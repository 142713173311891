import React from "react"
import Layout from "../components/Layout"
import { NarrowWrapper } from "../components/Layout"
import styled from "styled-components"
import { teal, typeScale } from "../utils"
import { HeaderOne } from "../components/Headers"
import { FiCheck } from "react-icons/fi"
import SEO from "../components/seo"

const success = () => {
  return (
    <>
      <SEO title="Success" />
      <Layout>
        <SuccessWrapper>
          <NarrowWrapper>
            <div className="flow">
              <CheckWrapper>
                <FiCheck />
              </CheckWrapper>
              <HeaderOne>You're in!</HeaderOne>
              <Text>
                Thank you for joining the CSS Academy tribe! It's wonderful to
                have you here. You should be receiving an email from me in the
                next few minutes.
              </Text>
            </div>
          </NarrowWrapper>
        </SuccessWrapper>
      </Layout>
    </>
  )
}

export const SuccessWrapper = styled.section`
  text-align: center;
`
export const CheckWrapper = styled.div`
  inline-size: 100px;
  block-size: 100px;
  border-radius: 50%;
  background: ${teal[400]};
  color: ${teal[800]};

  margin-inline-start: auto;
  margin-inline-end: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 4rem;
  }
`
const Text = styled.p`
  font-size: ${typeScale.header5};
`

export default success
